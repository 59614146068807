import { render, staticRenderFns } from "./CampaignEditorComponent.vue?vue&type=template&id=26688410&scoped=true"
import script from "./CampaignEditorComponent.vue?vue&type=script&lang=js"
export * from "./CampaignEditorComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26688410",
  null
  
)

export default component.exports