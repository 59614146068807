import { render, staticRenderFns } from "./MailCampaignInfo.vue?vue&type=template&id=87711628&scoped=true"
import script from "./MailCampaignInfo.vue?vue&type=script&lang=js"
export * from "./MailCampaignInfo.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "87711628",
  null
  
)

export default component.exports