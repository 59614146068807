import { render, staticRenderFns } from "./MetaChoiceComponent.vue?vue&type=template&id=5d444fac&scoped=true"
import script from "./MetaChoiceComponent.vue?vue&type=script&lang=js"
export * from "./MetaChoiceComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d444fac",
  null
  
)

export default component.exports