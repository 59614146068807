import { render, staticRenderFns } from "./EffectBlockList.vue?vue&type=template&id=2715e7a7&scoped=true"
import script from "./EffectBlockList.vue?vue&type=script&lang=js"
export * from "./EffectBlockList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2715e7a7",
  null
  
)

export default component.exports