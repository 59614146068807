import { render, staticRenderFns } from "./DataTableStaticComponent.vue?vue&type=template&id=d7914b62&scoped=true"
import script from "./DataTableStaticComponent.vue?vue&type=script&lang=js"
export * from "./DataTableStaticComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d7914b62",
  null
  
)

export default component.exports