import { render, staticRenderFns } from "./StarredQuestionsComponent.vue?vue&type=template&id=a22e272a&scoped=true"
import script from "./StarredQuestionsComponent.vue?vue&type=script&lang=js"
export * from "./StarredQuestionsComponent.vue?vue&type=script&lang=js"
import style0 from "./StarredQuestionsComponent.vue?vue&type=style&index=0&id=a22e272a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a22e272a",
  null
  
)

export default component.exports